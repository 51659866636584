@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div, input {
  font-family: 'Poppins', sans-serif !important;
}

input {
  outline: none;
  caret-color: #7D66FF;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: transparent !important;
  color: white !important;
  outline: none;
  background-clip: text;
}

#root {
  display: flex;
  height: 100%;
}

*:focus {
  outline: #AB9FFA solid 2px;
  border-radius: 5px;
}

input:focus {
  outline: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}